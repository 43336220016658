<template>
    <custom-table
        :title="$t('pages.system.sms.multiSend.title')"
        :subTitle="$t('pages.system.sms.multiSend.subTitle')"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:state="{ row: record }">
            <span :class="status[record.status_id].badge" class="badge">{{ $t('pages.system.sms.multiSend.status.' + status[record.status_id].code) }}</span>
        </template>
        <template v-slot:sendDate="{ row: record }">
            {{ record.send_date ? $moment(record.send_date).format("DD.MM.YYYY - HH:mm:ss") : $t('common.immediately') }}
        </template>
        <template v-slot:createdAt="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="detailRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                    </span>
                </a>
                <el-tooltip :content="$t('pages.system.sms.multiSend.phoneList')" placement="top">
                    <a v-on:click="exportExcel(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span v-if="!exportLoading" class="indicator-label">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/files/fil021.svg"/>
                            </span>
                        </span>
                        <span v-if="exportLoading" class="indicator-progress">
                            <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                        </span>
                    </a>
                </el-tooltip>

                <el-popconfirm v-if="record.status_id == 1 && record.send_date && $moment(record.send_date).add(-1, 'minutes') > $moment()" :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord(record)">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_detail" ref="detailModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_multi_send_detail_header">
                    <h2 class="fw-bolder">{{ $t('pages.system.sms.multiSend.save.detail.title') }}</h2>
                    <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17" v-if="Object.keys(form.detail).length">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_multi_send_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.system.sms.multiSend.cols.message') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.detail.message }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.system.sms.multiSend.cols.sendDate') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.detail.send_date ? $moment(form.detail.send_date).format("DD.MM.YYYY - HH:mm:ss") : $t('common.immediately') }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('common.createdAt') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ $moment(form.detail.created_at).format("DD.MM.YYYY - HH:mm:ss") }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('common.status') }}:</label>
                            <span :class="status[form.detail.status_id].badge" class="badge">{{ $t('pages.system.sms.multiSend.status.' + status[form.detail.status_id].code) }}</span>
                        </div>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.detailModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.system.sms.multiSend.cols.itemCount"),
                    key: "items_count"
                },
                {
                    name: this.$t("pages.system.sms.multiSend.cols.sendDate"),
                    scopedSlots: {customRender: "sendDate"}
                },
                {
                    name: this.$t("common.createdAt"),
                    scopedSlots: {customRender: "createdAt"}
                },
                {
                    name: this.$t('common.status'),
                    scopedSlots: {customRender: "state"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            form: {
                title: '',
                loading: false,
                detail: {},
                data: {}
            },
            status: {
                1: {
                    code: 'pending',
                    badge: 'badge-primary'
                },
                2: {
                    code: 'successful',
                    badge: 'badge-success'
                },
                3: {
                    code: 'failed',
                    badge: 'badge-danger'
                },
                4: {
                    code: 'partiallySuccessful',
                    badge: 'badge-warning'
                }
            },
            exportLoading: false
        }
    },
    computed: {
        table() {
            return this.$store.state.sms.multiSend.table;
        },
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.system.sms.multiSend.title"), [this.$t("menu.sms.title")]);
        this.$store.dispatch('sms/multiSend/get', {
            page: {},
            filterData: {
                sort: 'id:desc',
            }
        });
    },
    methods: {
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.$router.push({
                        path: "/system/sms/multi-send/save"
                    });
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("sms/multiSend/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("sms/multiSend/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'id:desc',
                }, filterData)
            });
        },
        deleteRecord(record) {
            if(!(record.status_id == 1 && record.send_date && this.$moment(record.send_date).add(-1, 'minutes') > this.$moment())) {
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.system.sms.multiSend.warning.cannot_delete"),
                })

                return false;
            }

            this.$store.dispatch("sms/multiSend/delete", {
                id: record.id
            });
        },
        detailRecord(record) {
            this.form.detail = record;
            this.showModal(this.$refs.detailModal);
        },
        exportExcel(record) {
            this.exportLoading = true;
            this.axios({
                url: this.sprintf('%s/%d', [this.endpoints['sms_multi_send_export'], record.id]),
                method: 'get',
                responseType: "blob"
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();
            }).finally(() => {
                this.exportLoading = false;
            });
        }
    }
}
</script>

<style></style>